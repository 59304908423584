.edit-poly-marker {
    margin-left: -4px !important;
    margin-top: -4px !important;
    width: 10px !important;
    height: 10px !important;
    background-color: var(--chakra-colors-black);
    border: 1px solid var(--chakra-colors-gray-200);
    border-radius: 50%;
}

.edit-poly-marker-midpoint {
    opacity: 1 !important;
    width: 8px !important;
    height: 8px !important;
    background-color: var(--chakra-colors-gray-600);
    border: 1px solid var(--chakra-colors-gray-200);
}

/* Prevent pointer from showing up on polygon path hover */
.leaflet-overlay-pane g path.leaflet-interactive {
    cursor: inherit;
}

.leaflet-container.crosshair-cursor-enabled {
    cursor: crosshair;
}

.leaflet-container {
    font-family: 'Inter', sans-serif;
}

/* Leaflet.DistortableImage overrides */
.ldi-icon {
    display: inline;
}

.leaflet-toolbar-icon.selected-mode {
    background-color: var(--chakra-colors-gray-500) !important;
}

.ldi .leaflet-popup-toolbar {
    border: 0;
}

.ldi .leaflet-popup-toolbar svg {
    fill: var(--chakra-colors-black);
}

.ldi .leaflet-toolbar-tip {
    display: none;
}

.ldi .ref-handle {
    margin-left: -4px !important;
    margin-top: -4px !important;
    width: 8px !important;
    height: 8px !important;
    border: none;
    border-radius: 50%;
    background-color: var(--chakra-colors-black);
}

/* end Leaflet.DistortableImage overrides */

.muni-label {
    background: rgba(255, 255, 255, 0);
    border: 0;
    border-radius: 0px;
    box-shadow: 0 0px 0px;
    font-weight: bold;
    font-size: var(--chakra-fontSizes-sm);
    color: var(--chakra-colors-purple-900);
    text-shadow: 0 0 0.2em var(--chakra-colors-purple-50),
        0 0 0.2em var(--chakra-colors-purple-50);
}

.muni-label-light {
    color: var(--chakra-colors-purple-50);
    text-shadow: 0 0 0.2em var(--chakra-colors-purple-900),
        0 0 0.2em var(--chakra-colors-purple-900);
}

.annotation-popup-div .leaflet-popup-content-wrapper {
    border-radius: 4px;
}

.annotation-popup-div .leaflet-popup-content-wrapper,
.annotation-popup-div .leaflet-popup-tip {
    background-color: var(--chakra-colors-gray-800);
}

.annotation-popup-div .leaflet-popup-content {
    height: 30px;
    margin: 0;
}
